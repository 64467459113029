<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="auto"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Tanggal</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>No. Bukti</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kontak</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kode Akun</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nama Akun</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Deskripsi</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Debit</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kredit</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Uraian</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Divisi</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Budgeting</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2"><label>{{ item['transaction_date'] }}</label></td>
                                <td class="px-2"><label>{{ item['transaction_number'] }}</label></td>
                                <td class="px-2"><label>{{ item['contact_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['chart_of_account_id'] }}</label></td>
                                <td class="px-2"><label>{{ item['chart_of_account_name'] }}</label></td>
                                <td class="px-2"><label>{{ item['desc'] }}</label></td>
                                <td class="px-2"><label>{{ item['debit'] }}</label></td>
                                <td class="px-2"><label>{{ item['credit'] }}</label></td>
                                <td class="px-2"><label>{{ item['notes'] }}</label></td>
                                <td class="px-2"><label>{{ item['division'] }}</label></td>
                                <td class="px-2"><label>{{ item['budgeting'] }}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import GeneralLedgerService from '../../Areas/Accounting/GeneralLedger/Script/GeneralLedgerService';

export default {
    name: 'LedgerPDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "BukuBesarGeneral_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a3',
                    orientation: 'portrait'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(StartDate, EndDate) {
            const variables = {
                startDate: StartDate,
                endDate: EndDate
            }
            var data = await GeneralLedgerService.getAllLedgerData(variables);
            var itemData = GeneralLedgerService.itemPDF(data)
            this.items = itemData;

            this.$refs.html2Pdf.generatePdf();
        },
        formatDate(data){
            if (data){
                var result = moment(data).format('yyyy-MM-DD');
                return result;                
            }
        }
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>